import './qualifications.css';
import {useEffect} from 'react';

function Qualifications () {
    useEffect(() => {
        document.getElementById('scroller')
            .addEventListener('wheel', function(event) {
                if (event.deltaMode === event.DOM_DELTA_PIXEL) {
                    var modifier = 1;
                    // иные режимы возможны в Firefox
                } else if (event.deltaMode === event.DOM_DELTA_LINE) {
                    var modifier = parseInt(getComputedStyle(this).lineHeight);
                } else if (event.deltaMode === event.DOM_DELTA_PAGE) {
                    var modifier = this.clientHeight;
                }
                if (event.deltaY !== 0) {
                    // замена вертикальной прокрутки горизонтальной
                    this.scrollLeft += modifier * event.deltaY;
                    event.preventDefault();
                }
            });
    }, [])
    return (
        <div>
            <span className='qualification-text'>Мнение</span>
            <div id="scroller" className="qualifications-container">
                <div className="qualification">
                    <h1>Третья мировая война</h1>
                    <div className="content html">
                        Последнее время много о ней говорится и пишется. Кто-то считает, что её нет и не будет, кто-то - уже идёт. Да, третья мировая война идёт. И она началась не 24 февраля 2022 года, она началась намного раньше. А 24 февраля началась спецоперация в этой войне и для этой спецоперации есть несколько причин. <br/><br/>
                        Цитата: <br/>
                        «Третья мировая началась с распадом СССР. За 90-е мы официально потеряли 10 млн. населения. Война ведь - это не только когда пушки стреляют. А сейчас у нас если брать Великую Отечественную, где то Курская дуга. Добиваем врага на своей территории и будем бить его на чужой.»
                    </div>
                </div>
                <div className="qualification">
                    <h1>Причины</h1>
                    <div className="content html">
                        Причины, мне кажется очевидны. Первая - Россия была гарантом Минских соглашений. Но так как Украина их не выполнила, Россия пошла на защиту в рамках этих гарантий, на защиту мирных граждан, женщин и детей, а за 8 лет вооружённые силы Украины убили более 10 000 гражданского мирного населения! Своя же армия, которая должна защищать, а не убивать граждан своей страны! Я считаю, это очень веская причина начать военную операцию. Вторая причина - разведка ВС РФ работает и свою работу делает на отлично. И ведь не я один понимаю, что идёт третья мировая война, война против России за её энергоносители.
                    </div>
                </div>
                <div className="qualification">
                    <h1>Но с кем война?</h1>
                    <div className="content html">
                        Кто с кем воюет? Многие думают, что Россия с Украиной. Но это не так, от слова «совсем». Война идёт между США+ЕС, с одной стороны, и Россией, с другой стороны. И как сказал певец Денис Майданов - «Россия не начинает войны, она их заканчивает». Но так как США и ЕС хитрожопые трусы и, что бы не уничтожать свои народы, свою инфраструктуру и свои страны, они воюют руками Украины - жизнями украинцев. Они много лет промывали мозги украинцам и раскачивали Украину и в 2014 году началось.
                    </div>
                </div>
                <div className="qualification">
                    <h1>Спецоперация</h1>
                    <div className="content html">
                        Многие говорят, что спецоперацию надо было в 2014 году проводить, что вроде жертв было бы меньше. Но их могло быть больше, Россия была неготова к войне. Украину готовили почти 20 лет. Почему-то многие считают, что Путин начал спецоперацию на ровном месте. Но это ведь не так! Прошло восемь лет, а вы сами вспомните, что было за эти годы - военные реформы, служба по контракту, вооружение, да тот же «Танковый биатлон», укрепление отношений с Белорусией. И почти каждый шаг был продуман, конечно есть вещи, которые не смогли предугадать. Ведь над операцией работал ни только Путин, а все структуры - в первую очередь разведка Минобороны, Минфин, Минэконом. Что они не думали о новых санкциях? Да они на 100% о них знали и готовились к этому. Они не знали, что экономику будет трясти? Знали. Но вы посмотрите, кто больше страдает от этих санкций? Да, санкцидатели, потому что Россия была готова. Да, цены поднимаются, но когда они не поднимались?
                    </div>
                </div>
                <div className="qualification">
                    <h1>Медленно, но верно</h1>
                    <div className="content html">
                        Спецоперация идёт медленно, потому что, во-первых, у России нет цели захватить страну, захватить украинские земли. Есть цель - освобождение от киевско-нацисткского режима. Поэтому бьют точечно по ВСУ, а не сметают всё подряд на своём пути. Во-вторых, правительство РФ прекрасно понимает, что восстанавливать Украину будет Россия. Ни США, ни ЕС не будут восстанавливать, потому что они скажут, что уже и так много чем помогли - оружием и финансами, ну и, конечно же, будут сами зализывать свои раны. <br/><br/>
                        Наши враги подставили Украину, закидали нас санкциями и решили, что Россия поднимет руки и закричит: «Сдаёмсюю», но не вышло и теперь им самим ещё больше расхлёбывать от последствий их же войны.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Qualifications;