import './home.css';
import ZaPobedu from '../../images/za_pobedu.jpg';
import {BsMouse} from "react-icons/bs";

function Home () {
    return (
        <div id='home' className='container home-container'>
            <div className='logo'>
                <div className="main-img">
                    <span className="circle"></span>
                    <span className="circle"></span>
                    <span className="circle"></span>
                    <span className="circle"></span>
                    <span className="circle"></span>
                    <span className="circle"></span>
                    <span className="circle"></span>
                    <span className="circle"></span>
                </div>
                <img src={ZaPobedu} alt='Zа победу'/>
            </div>
            <a href="#footer" className='scroll-down'>
                <hr/>
                <h5>scroll</h5>
                <BsMouse className='scroll' />
                <hr/>
            </a>

            <h2>
                <span>Екатерина РОМАЩУК</span> <br/>
                <p>
                    Мой город охрип от молитв,<br/>
                    Мой город оглох от бомбёжек,<br/>
                    Мой город сегодня безлик...<br/>
                    Прошу: защити его, Боже!<br/>
                    Голодный, как брошенный пёс,<br/>
                    И часто дрожит от озноба.<br/>
                    Мой город, уставший от слёз,<br/>
                    Ещё уповает на Бога...<br/>
                    Калека, бессильный на вид,<br/>
                    Но тлеет в нём дух поколений.<br/>
                    Мой город стоит на крови...<br/>
                    За то, что не стал на колени 
                </p>
            </h2>
        </div>
    )
}
// const toggle = document.querySelectorAll('.hover-show');
// const toggle = document.querySelector('.main-img');
// toggle.addEventListener('click', () => {
//     toggle.classList.toggle('active');
// });


export default Home;
