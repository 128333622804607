import './footer.css';
import {BsMouse} from "react-icons/bs";
// import {BsInstagram} from "react-icons/bs";
// import {BsFacebook} from "react-icons/bs";
// import {TiSocialDribbble} from "react-icons/ti";
// import {TiSocialYoutube} from "react-icons/ti";
// import {TiSocialGithub} from "react-icons/ti";

function Footer () {
    return (
        <div id='footer' className='container footer-container'>
            <h1>Сила V правде
                <a href="#home">
                    <h2>
                        <BsMouse /> - Наверх -
                    </h2>
                </a>
            </h1>
            {/*<div className="social-links">*/}
            {/*    <a href="/">*/}
            {/*        <BsInstagram className='social' />*/}
            {/*    </a>*/}
            {/*    <a href="/">*/}
            {/*        <BsFacebook className='social' />*/}
            {/*    </a>*/}
            {/*    <a href="/">*/}
            {/*        <TiSocialDribbble className='social' />*/}
            {/*    </a>*/}
            {/*    <a href="/">*/}
            {/*        <TiSocialYoutube className='social' />*/}
            {/*    </a>*/}
            {/*    <a href="/">*/}
            {/*        <TiSocialGithub className='social' />*/}
            {/*    </a>*/}
            {/*</div>*/}
        </div>
    )
}

export default Footer;