import './header.css';
import logo from '../../images/z1.png';

function Header () {
    return (
        <div id='home' className='container header-container'>
            <div className='header-container-title'>
                <p>
                    <img src={logo} className="header-container-logo" alt="Zа попеду" />
                </p>
                Zа победу
                <h2>Сила V правде</h2>
            </div>
        </div>
    )
}

export default Header;